import React from "react";
import Index from "../../../../component/Index";
import { postGymDetail } from "../../../../redux/features/slices/Admin/AdminService";
import PageIndex from "../../../PageIndex";

const GymList = ({ trainerId }) => {
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  const [users, setUsers] = PageIndex.useState([]);
  const [anchorEl, setAnchorEl] = PageIndex.useState(null);
  const [amemties, setAmemties] = PageIndex.useState([]);
  const [equipment, setEquipment] = PageIndex.useState([]);
  const [anchorEquip, setAnchorEquip] = PageIndex.useState(null);
  const [anchorService, setAnchorService] = PageIndex.useState(null);
  const [gymServices, setGymServices] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  const openService = Boolean(anchorService);
  const openEqip = Boolean(anchorEquip);
  const open = Boolean(anchorEl);
  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(users);
    setSearchedData(
      users.filter(
        (item) =>
          item?.gymName?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.startTime?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.endTime?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.location?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.staff
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toString()?.toLowerCase())
      )
    );
  };
  const fetchData = async () => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", trainerId ? trainerId : location?.state?._id);
    dispatch(postGymDetail(urlEncodedData)).then((response) => {
      const { status, data } = response?.payload || {};
      if (status === 200) {
        const dataWithCount = data?.map((item, index) => ({
          ...item,
          count: index + 1,
          key: index,
        }));
        setUsers(dataWithCount);
        setSearchedData(dataWithCount);
        setCurrentRows(dataWithCount);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  PageIndex.useEffect(() => {
    fetchData();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickEquip = (event) => {
    setAnchorEquip(event.currentTarget);
  };

  const handleCloseEquip = () => {
    setAnchorEquip(null);
  };
  const handleClickService = (event) => {
    setAnchorService(event.currentTarget);
  };

  const handleCloseService = () => {
    setAnchorService(null);
  };
  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <div>
      <Index.Box>
        <Index.Box sx={{ width: "100%" }} className="add-user-height">
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Typography className="admin-page-title user-list-page-title"></Index.Typography>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        onChange={(e) => requestSearch(e.target.value)}
                        placeholder="Search"
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="gymList-table-main page-table-main cus-page-table-main">
                        {/* page-table-main */}
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table-user-maindata"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Sr. no
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Gym Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Gym NAME
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Amenities
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Equipment
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Services
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Staff
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Start Time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  End Time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Location
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {currentRows?.length && apiData ? (
                                currentRows?.map((row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Box className="userlist-data anchor-link">
                                        {row?.count ? row?.count : "-"}
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <img
                                        src={
                                          row?.gymImg
                                            ? row?.gymImg instanceof Blob
                                              ? URL.createObjectURL(row?.gymImg)
                                              : `${PageIndex.ImageURL}${row?.gymImg}`
                                            : Index?.Png?.herobg
                                        }
                                        alt="loading..."
                                        className="user-post-user-img"
                                      />
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.gymName ? row?.gymName : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td gym-list-table-cell"
                                    >
                                      {row.amenities.map((item) => item).join(', ')}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.equipment.map((item) => item).join(', ')}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.services.map((item) => item).join(', ')}
                                    </Index.TableCell>

                                    {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.staff ? row?.staff : "-"}
                                    </Index.TableCell> */}
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.staff && row.staff.length > 26 ? `${row.staff.slice(0, 23)}...` : (row?.staff || "-")}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.startTime ? row?.startTime : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.endTime ? row?.endTime : "-"}
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.location ? row?.location : "-"}
                                    </Index.TableCell> */}
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.location ? (
                                        row.location.length > 26 ? `${row.location.slice(0, 23)}...` : row.location
                                      ) : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={10}
                                    align="center"
                                  >
                                    {!apiData ? (
                                      <PageIndex.Loader />
                                    ) : (
                                      "No data found"
                                    )}
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box>
                        <Index.Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {amemties?.map((item) => (
                            <Index.MenuItem key={item} onClick={handleClose}>
                              {item}
                            </Index.MenuItem>
                          ))}
                        </Index.Menu>
                      </Index.Box>
                      <Index.Box>
                        <Index.Menu
                          id="basic-menu"
                          anchorEl={anchorEquip}
                          open={openEqip}
                          onClose={handleCloseEquip}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {equipment?.map((item) => (
                            <Index.MenuItem
                              key={item}
                              onClick={handleCloseEquip}
                            >
                              {item}
                            </Index.MenuItem>
                          ))}
                        </Index.Menu>
                      </Index.Box>
                      <Index.Box>
                        <Index.Menu
                          id="basic-menu"
                          anchorEl={anchorService}
                          open={openService}
                          onClose={handleCloseService}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {gymServices?.map((item) => (
                            <Index.MenuItem
                              key={item}
                              onClick={handleCloseService}
                            >
                              {item}
                            </Index.MenuItem>
                          ))}
                        </Index.Menu>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          setCurrentRows={setCurrentRows}
                          allData={searchedData}
                          pageData={currentRows}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default GymList;
