import React from "react";
import Index from "../../../../../component/Index";
import PageIndex from "../../../../PageIndex";
import PropTypes from "prop-types";
import { getSingleUserDetails } from "../../../../../redux/features/slices/Admin/AdminService";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../../redux/features/slices/Admin/AdminSlice";
import BasicDetails from "./tabs/BasicDetails";
import FitnessDetails from "./tabs/FitnessDetails";
import OtherDetails from "./tabs/OtherDetails";
import ChallengeDetails from "./tabs/ChallengeDetails";
import OrderDetails from "./tabs/OrderDetails";
// import CourseDetails from "./tabs/CourseDetails";
import SocialDetails from "./tabs/SocialDetails";
import ListTab from "../../../../../component/common/ListTab";
import GymList from "../../trainer-list/GymList";
import ClentListData from "../../trainer-list/ClientListData";
import ProductList from "../../trainer-list/ProductList";
import TimingDetails from "../../trainer-list/TimingDetails";
import Appointment from "../../trainer-list/Appointment";
import SessionTabList from "../../trainer-list/SessionTabList";
import NutritionTabPlan from "../../trainer-list/NutritionTabPlan";
import WorkoutPlan from "../../trainer-list/WorkoutPlan";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewSingleUserTab = () => {
  const [value, setValue] = PageIndex.useState(0);
  const [trainerValue, setTrainerValue] = PageIndex.useState(0);
  const { editId } = PageIndex.useParams();
  const { state } = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const [userInfo, setUserInfo] = PageIndex.useState({});
  // console.log(state.userType, "State");
  const getSingleInfo = async () => {
    dispatch(getSingleUserDetails(editId))
      .then((res) => {
        setUserInfo(res.payload.data);
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  // Back Button
  const handleBack = () => {
    navigate("/admin/user");
  }

  PageIndex.useEffect(() => {
    getSingleInfo();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTrainer = (event, newValue) => {
    setTrainerValue(newValue);
  };

  return (
    <Index.Box className="dashboard-containt-main">
      <PageIndex.DynamicTitle title="Fitrizer - User Details" />
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Grid container spacing={2} className="align-items-center">
          <Index.Grid item xs={8} md={8}>
            <Index.Typography
              className="admin-page-title  add-user-title add-user-page-title"
              component="h2"
              variant="h2"
            >
              User Details
            </Index.Typography>
          </Index.Grid>
          <Index.Grid item xs={4} md={4}>
            <Index.Box className='form-back-btn'>
              <Index.Button variant="contained"
                className="back-btn"
                type='back'
                onClick={() => handleBack()}
              >
                <Index.KeyboardBackspaceIcon />
              </Index.Button>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }} className="add-user-data-main mb-16">
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label={"Personal Details"}
                  {...a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label={"Fitness Details"}
                  {...a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label={"Challenge Details"}
                  {...a11yProps(2)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label={"Order Details"}
                  {...a11yProps(3)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label={"Social Details"}
                  {...a11yProps(4)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label={"Other Details"}
                  {...a11yProps(5)}
                  className="admin-tab"
                  disableRipple
                />
                {/* <Index.Tab
                  label={"Course Details"}
                  {...a11yProps(6)}
                  className="admin-tab"
                  disableRipple
                /> */}
              </Index.Tabs>

              <TabPanel value={value} index={0} className="admin-tabpanel">
                <BasicDetails userInfo={userInfo} />
              </TabPanel>
              <TabPanel value={value} index={1} className="admin-tabpanel">
                <FitnessDetails userInfo={userInfo} />
              </TabPanel>
              <TabPanel value={value} index={2} className="admin-tabpanel">
                <ChallengeDetails challengeDetails={userInfo?.challengeDetails} />
              </TabPanel>
              <TabPanel value={value} index={3} className="admin-tabpanel">
                <OrderDetails orderDetails={userInfo?.orderWithAddress} />
              </TabPanel>
              <TabPanel value={value} index={4} className="admin-tabpanel">
                <SocialDetails feedsDetails={userInfo?.feedsDetails} />
              </TabPanel>
              <TabPanel value={value} index={5} className="admin-tabpanel">
                <OtherDetails userInfo={userInfo} />
              </TabPanel>
              {/* <TabPanel value={value} index={6} className="admin-tabpanel">
                <CourseDetails userInfo={userInfo} />
              </TabPanel> */}
            </Index.Box>

          </Index.Box>
        </Index.Box>

        {state?.userType === "Trainer" && (
          <>
            <Index.Grid container spacing={2} className="align-items-center">
              <Index.Grid item xs={12} md={12}>
                <Index.Typography
                  className="admin-page-title  add-user-title add-user-page-title mb-0"
                  component="h2"
                  variant="h2"
                >
                  Trainer Details
                </Index.Typography>
              </Index.Grid>
            </Index.Grid>
            <Index.Box className="add-user-data-main tabs-main-box mt-16">
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <ListTab
                    value={trainerValue}
                    onChange={handleChangeTrainer}
                    label1={"Gym List"}
                    label2={"Client List"}
                    label3={"Product List"}
                    label4={"Timing Details"}
                    label5={"Appointment"}
                    label6={"Sessions"}
                    label7={"Nutrition Plan"}
                    label8={"Workout Plan"}
                    children1={<GymList trainerId={editId}/>}
                    children2={<ClentListData trainerId={editId}/>}
                    children3={<ProductList trainerId={editId} />}
                    children4={<TimingDetails trainerId={editId} />}
                    children5={<Appointment trainerId={editId}/>}
                    children6={<SessionTabList trainerId={editId} type={'trainer'}/>}
                    children7={<NutritionTabPlan trainerId={editId}/>}
                    children8={<WorkoutPlan trainerId={editId}/>}
                    tabVal={trainerValue}
                  ></ListTab>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}

      </Index.Box>
    </Index.Box>
  );
};

export default ViewSingleUserTab;
