import React from "react";
import { NavLink } from "react-router-dom";
import Index from "../../Index";

const AdminMenus = ({
  pathname,
  removeClass,
  openUserManagement,
  openMasterRecord,
  handleOpenCms,
  openNFT,
  openIndividualChallenges,
  openDropdownIndex,
  openPosts,
  openOrders,
  openInventory,
  openTrainer,
  openCMS,
}) => {
  return (
    <Index.List className="admin-sidebar-list">
      {/* Dashboard */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/dashboard"
          className={
            pathname?.pathname === "/admin/dashboard"
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.GroupsIcon />
          </Index.Box>
          Dashboard
        </Index.Link>
      </Index.ListItem>

      {/* User Management */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(1)}
      >
        <Index.Link className="admin-sidebar-link submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <img
                  src={Index.Svg.usericon}
                  className="admin-sidebar-icon"
                  alt=""
                />
              </Index.Box>
              User Management
            </Index.Box>
            {openUserManagement ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openUserManagement}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                {/* Users */}

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/user"
                    className={
                      pathname?.pathname.includes("/user/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Users
                  </NavLink>
                </Index.ListItem>

                {/* Trainers */}

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/trainer-list"
                    className={
                      pathname?.pathname.includes("/trainer-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Trainers
                  </NavLink>
                </Index.ListItem>

                {/* Sub Admins */}

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/sub-admins"
                    className={
                      pathname?.pathname.includes("/sub-admins")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Sub Admins
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Master Records */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(2)}
      >
        <Index.Link className="admin-sidebar-link submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.WifiProtectedSetupIcon />
              </Index.Box>
              Master Records
            </Index.Box>
            {openMasterRecord ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openMasterRecord}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                {/* Goal Setup */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/goal-setup"
                    className={
                      pathname?.pathname.includes("/goal-setup/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Goal Setup
                  </NavLink>
                </Index.ListItem>

                {/* NFT Setup */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/nft-setup"
                    className={
                      pathname?.pathname.includes("/nft-setup/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    NFT Setup
                  </NavLink>
                </Index.ListItem>

                {/* NFT Repair Setup */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/nft-repair-setup"
                    className={
                      pathname?.pathname.includes("/nft-repair-setup/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    NFT Repair Setup
                  </NavLink>
                </Index.ListItem>

                {/* Recipe */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/receipe-plans"
                    className={
                      pathname?.pathname.includes("/receipe-plans/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Recipe
                  </NavLink>
                </Index.ListItem>

                {/* Supplier */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/inventory/supplier/supplier"
                    className={
                      pathname?.pathname.includes("/supplier/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Supplier
                  </NavLink>
                </Index.ListItem>

                {/* Warehouse */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/inventory/warehouse/warehouse"
                    className={
                      pathname?.pathname.includes("/warehouse/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Warehouse
                  </NavLink>
                </Index.ListItem>

                {/* Store */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/inventory/store/store"
                    className={
                      pathname?.pathname.includes("/store/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Store
                  </NavLink>
                </Index.ListItem>

                {/* Coupons */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/inventory/coupons/view-coupons"
                    className={
                      pathname?.pathname.includes("/coupons/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Coupons
                  </NavLink>
                </Index.ListItem>

                {/* Center Types */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/center-types"
                    className={
                      pathname?.pathname.includes("/center-types/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Center Types
                  </NavLink>
                </Index.ListItem>

                {/* Amenities */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/amenities"
                    className={
                      pathname?.pathname.includes("/amenities/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Amenities
                  </NavLink>
                </Index.ListItem>

                {/* Equipments */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/equipments"
                    className={
                      pathname?.pathname.includes("/equipments/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Equipments
                  </NavLink>
                </Index.ListItem>

                {/* Services */}
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/services"
                    className={
                      pathname?.pathname.includes("/services/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Services
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* NFT */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(3)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.ViewListIcon />
              </Index.Box>
              NFT
            </Index.Box>
            {openDropdownIndex && openNFT ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openNFT}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/nft-listing"
                    className={
                      pathname?.pathname.includes("/nft-listing/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    NFT Listing
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/lease-nft-listing"
                    className={
                      pathname?.pathname.includes("/lease-nft-listing/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Lease NFT
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/repair-nft-request"
                    className={
                      pathname?.pathname.includes("/repair-nft-request/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Repair NFT Request
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/nft-transaction-list"
                    className={
                      pathname?.pathname.includes("/nft-transaction-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    NFT Transactions
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Posts */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(4)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.ModeCommentIcon />
              </Index.Box>
              Posts
            </Index.Box>
            {openDropdownIndex && openPosts ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openPosts}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/posts"
                    className={
                      pathname?.pathname.includes("/posts/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    User Posts
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/report/reportpost"
                    className={
                      pathname?.pathname.includes("/reportpost/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Reported Post
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/report/reportuser"
                    className={
                      pathname?.pathname.includes("/reportuser/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Reported User
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/sponsored-posts"
                    className={
                      pathname?.pathname.includes("/sponsored-posts/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Sponsored Posts
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Challenges List */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/challenges"
          className={
            pathname?.pathname === "/admin/challenges"
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.KayakingIcon />
          </Index.Box>
          Challenges List
        </Index.Link>
      </Index.ListItem>

      {/* Individual Challenges */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(5)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.SportsHandballIcon />
              </Index.Box>
              Individual Challenges
            </Index.Box>
            {openDropdownIndex && openIndividualChallenges ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openIndividualChallenges}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/usergoalsetup"
                    className={
                      pathname?.pathname.includes("/usergoalsetup/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    User Goal Setup
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Community Groups */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/communitygroup"
          className={
            pathname?.pathname === "/admin/communitygroup"
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.GroupsIcon />
          </Index.Box>
          Community Groups
        </Index.Link>
      </Index.ListItem>

      {/* Orders */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(6)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.AddShoppingCartIcon />
              </Index.Box>
              Orders
            </Index.Box>
            {openDropdownIndex && openOrders ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openOrders}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/orders/list"
                    className={
                      pathname?.pathname.includes("/list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Orders
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/orders/rating-list"
                    className={
                      pathname?.pathname.includes("/rating-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Orders Rating
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/order-tickets"
                    className={
                      pathname?.pathname.includes("/order-tickets/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Order Tickets
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Inventory */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(7)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.InventoryIcon />
              </Index.Box>
              Inventory
            </Index.Box>
            {openDropdownIndex && openInventory ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openInventory}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/category/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/category/category"
                  >
                    Category
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/sub-category/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/sub-category/sub-category"
                  >
                    Sub Category
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/brand")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/brand/brand"
                  >
                    Brand
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname === "/admin/inventory/product/product"
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/product/product"
                  >
                    Product
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("trainer-products")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/trainer-products"
                  >
                    Trainer Products
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("stock-report")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/report/stock-report"
                  >
                    Stock report
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("sales-report")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/inventory/report/sales-report"
                  >
                    Sales report
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* Transaction History */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/wallet"
          className={
            pathname?.pathname === "/admin/wallet"
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.WalletIcon />
          </Index.Box>
          Transaction History
        </Index.Link>
      </Index.ListItem>

      {/* Trainer */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(8)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.Groups3Icon />
              </Index.Box>
              Trainer
            </Index.Box>
            {openDropdownIndex && openTrainer ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openTrainer}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/appointment-list"
                    className={
                      pathname?.pathname.includes("/appointment-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Appointments list
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/session-list"
                    className={
                      pathname?.pathname.includes("/session-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Session list
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/nutrition-plans"
                    className={
                      pathname?.pathname.includes("/nutrition-plans/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Nutrition Plans
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/workout-plans"
                    className={
                      pathname?.pathname.includes("/workout-plans/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Workout Plans
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    to="/admin/session-subscription-list"
                    className={
                      pathname?.pathname.includes("/session-subscription-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                  >
                    Subscription list
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>

      {/* User Role */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/role/role"
          className={
            pathname?.pathname.includes("role")
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.SettingsAccessibilityIcon />
          </Index.Box>
          User Role
        </Index.Link>
      </Index.ListItem>

      {/* General Settings */}
      <Index.ListItem className="admin-sidebar-listitem">
        <Index.Link
          to="/admin/general-setting"
          className={
            pathname?.pathname === "/admin/general-setting"
              ? "admin-sidebar-link active"
              : "dashboard-header"
          }
          onClick={removeClass}
        >
          <Index.Box className="admin-sidebar-icons">
            <Index.SettingsIcon />
          </Index.Box>
          General Settings
        </Index.Link>
      </Index.ListItem>

      {/* CMS */}
      <Index.ListItem
        className="admin-sidebar-listitem"
        onClick={() => handleOpenCms(9)}
      >
        <Index.Link className="admin-sidebar-link  submenu-list-details">
          <Index.Box className="admin-submenu-details">
            <Index.Box className="admin-text-details-sub">
              <Index.Box className="admin-sidebar-icons">
                <Index.CMSIcon />
              </Index.Box>
              CMS
            </Index.Box>
            {openDropdownIndex && openCMS ? (
              <Index.ExpandLess className="expandmore-icon" />
            ) : (
              <Index.ExpandMore className="expandless-icon" />
            )}
          </Index.Box>
          <Index.Box className="submenu-main">
            <Index.Collapse
              in={openCMS}
              timeout="auto"
              unmountOnExit
              className="submenu-collapse"
            >
              <Index.List
                component="div"
                disablePadding
                className="admin-sidebar-submenulist"
              >
                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/about-us/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/about-us"
                  >
                    About Us
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/privacy-policy/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/privacy-policy"
                  >
                    Privay Policy
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/term-condition/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/term-condition"
                  >
                    Terms & Conditions
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("/faq-list/")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/faq-list"
                  >
                    FAQ
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("tickets/list")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/tickets/list"
                  >
                    Tickets
                  </NavLink>
                </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                  <NavLink
                    className={
                      pathname?.pathname.includes("contact-request")
                        ? "sub-menu admin-sidebar-link active"
                        : "sub-menu admin-sidebar-link"
                    }
                    to="/admin/cms/contact-request"
                  >
                    Contact Request
                  </NavLink>
                </Index.ListItem>
              </Index.List>
            </Index.Collapse>
          </Index.Box>
        </Index.Link>
      </Index.ListItem>
    </Index.List>
  );
};

export default AdminMenus;
